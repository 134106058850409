.album img {
    height: 60vmin;
    margin-top: 50px;
}

.album .availability-list {
    display: flex;
    justify-content: space-evenly;
    height: 100px;
    align-items: center;
}

.album .availability-list a {
    font-size: 1.3em;
    color: white;
    text-decoration: none;
}