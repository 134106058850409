.App {
  text-align: center;
}

.App .logo {

}

.App .text-hi {
  font-size: 3em;
  pointer-events: none;
}

.App .logo {
  height: 70vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App .logo {
    animation: App-logo-spin infinite 2s alternate;
  }
}

@keyframes App-logo-spin {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 10px);
  }
}
