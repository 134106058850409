.links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-height: 60px;
    flex-wrap: wrap;
}

.links a {
    font-size: 1.5em;
    color: white;
    text-decoration: none;
    transition: transform ease-in-out 250ms;
    transition-property: color,transform;
    margin-top: 12px;
    margin-left: 12px;
    margin-right: 12px;
}

.links .link-spotify:hover {
    color: #1ed760;
}

.links .link-youtube:hover {
    color: #ff0000;
}

.links .link-apple:hover {
    color: #d0d0d0;
}

.links .link-facebook:hover {
    color: #2e89ff;
}

.links .link-twitter:hover {
    color: #1d9bf0;
}

.links .link-instagram:hover {
    color: #c209a3;
}

.links svg {
    margin-right: 6px;
}

.links a:hover {
    text-decoration: underline;
    transform: scale(1.2);
}